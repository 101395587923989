import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import {  GeneralResponse } from "types";
import { Project } from "hooks";

export type UseCreateProjectResponse = GeneralResponse<number>;

export type UseCreateProjectVariables = Omit<Project, "id" | "isVisible" | "isVisibleMain">;

export function useCreateProject(
  props?: MutationParams<UseCreateProjectResponse, unknown, UseCreateProjectVariables>
) {
  return useAPIMutation<UseCreateProjectResponse, unknown, UseCreateProjectVariables>({
    ...props,
    url: "/api/projects/create",
    instance: generalRequest,
    method: "POST",
  });
}
