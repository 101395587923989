import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { VacancyBannerNullable } from "hooks";

export type UseGetVacancyBannerResponse = GeneralResponse<VacancyBannerNullable>;

export function useGetVacancyBanner(props?: QueryParams<UseGetVacancyBannerResponse>) {
  return useAPIQuery<UseGetVacancyBannerResponse>({
    ...props,
    url: "/api/common-announcement/get",
    instance: generalRequest,
  });
}
