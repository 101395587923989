import { PropsWithChildren, useEffect } from "react";

import AuthContext from "./context";
import useValue from "./useValue";

export default function AuthProvider({ children }: PropsWithChildren) {
  const values = useValue();

  useEffect(() => {
    const handleStorageChange = () => {
      values.actions.setIsAuth(!!localStorage.getItem("token"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [values.actions]);

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}
