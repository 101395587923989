import { useState } from "react";

const useAuthContextValue = () => {
  const [isAuth, setIsAuth] = useState(!!localStorage.getItem("token"));

  return {
    state: {
      isAuth,
    },
    actions: {
      setIsAuth,
    },
  };
};

export default useAuthContextValue;
