import classes from "./Language.module.scss";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { useLanguage } from "hooks";
import { Languages } from "types";

const Language = () => {
  const { currentLang, languageItems, changeLang } = useLanguage();

  return (
    <Dropdown
      menu={{
        items: languageItems,
        onClick: (info) => changeLang(info.key as Languages),
        selectedKeys: [currentLang?.key ?? ""]
      }}
      className={classes.dropdown}
    >
      <Space>
        <span>{currentLang?.label}</span>
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};

export default Language;
