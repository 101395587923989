import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { UsefulNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetUsefulByIdResponse = GeneralResponse<UsefulNullable>;

export function useGetUsefulById(id?: number | string, props?: QueryParams<UseGetUsefulByIdResponse>) {
  return useAPIQuery<UseGetUsefulByIdResponse>({
    ...props,
    url: `/api/useful-page/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}