import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { General, UseCreateGeneralVariables } from "hooks";

export type UseUpdateGeneralResponse = GeneralResponse<number>;

export type UseUpdateGeneralVariables =  Pick<General, "id"> & UseCreateGeneralVariables;

export function useUpdateGeneral(
  props?: MutationParams<UseUpdateGeneralResponse, unknown, UseUpdateGeneralVariables>
) {
  return useAPIMutation<UseUpdateGeneralResponse, unknown, UseUpdateGeneralVariables>({
    ...props,
    url: "/api/general-information/update",
    instance: generalRequest,
    method: "PUT",
  });
}
