import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangeSpeechVisibleResponse = GeneralResponse<boolean>;

export type UseChangeSpeechVisibleVariables = ChangeVisibleVariables;

export function useChangeSpeechVisible(
  props?: MutationParams<
    UseChangeSpeechVisibleResponse,
    unknown,
    UseChangeSpeechVisibleVariables
  >
) {
  return useAPIMutation<
    UseChangeSpeechVisibleResponse,
    unknown,
    UseChangeSpeechVisibleVariables
  >({
    ...props,
    url: "/api/leaders-speech/change-is-visible",
    instance: generalRequest,
    method: "PUT",
  });
}
