import { FC } from 'react';

import './Select.scss';
import { Select as AntSelect, SelectProps } from 'antd';
import cn from 'classnames';

type Props = SelectProps<any> & {
  className?: string;
  fullWidth?: boolean;
  label?: string;
  size?: string;
  defaultValue?: any;
  value?: any;
  options?: any;
  background?: 'white' | 'lightBlue';
  // onChange?: (val:any) => void;
};

const Select: FC<Props> = ({
  children,
  label,
  className,
  fullWidth,
  options,
  size,
  background = 'white',
  // onChange,
  ...props
}) => {
  const classNames = cn(
    background === 'white' && 'select',
    background === 'lightBlue' && 'blue-select',
    className && className,
    fullWidth && 'full-width',
    size && size
  );

  const { Option } = AntSelect;

  return (
    <div style={{ position: 'relative' }}>
      {label && <span className={'select-label'}>{label}</span>}
      <AntSelect className={classNames} allowClear options={options} {...props}>
        {options &&
          options.map((i: any, key: number) => (
            <Option key={key} value={String(i?.value)}>
              {i?.label}
            </Option>
          ))}
      </AntSelect>
    </div>
  );
};

export default Select;
