import { ComponentProps, FC } from "react";
import { useTranslation } from "react-i18next";
import classes from "./Download.module.scss";
import cn from "classnames";
import { Button, Svg } from "components/shared";

type ButtonComponent = ComponentProps<typeof Button>;

interface Props extends Omit<ButtonComponent, "onClick"> {
  show?: boolean;
  isDownloading?: boolean;
  onDownload?: ButtonComponent["onClick"];
}

const Download: FC<Props> = ({ isDownloading, onDownload, show, ...props }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <Button
        {...props}
        onClick={onDownload}
        size="large"
        className={cn(classes.button)}
        loading={isDownloading}
        icon={<Svg icon="excel" />}
      >
        {t("Скачать")}
      </Button>
    </div>
  );
};

export default Download;
