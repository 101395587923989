import { usePagination } from "hooks";

export type useTablePaginationConfigProps = {
  total: number;
  pagination: ReturnType<typeof usePagination>;
};

export function useTablePaginationConfig({
  total,
  pagination,
}: useTablePaginationConfigProps) {
  return total > pagination.pageSize
    ? {
        current: pagination.page + 1,
        pageSize: pagination.pageSize,
        total,
        showSizeChanger: false,
        onChange(page: number, pageSize: number) {
          pagination.setPage(page - 1);
          pagination.setPageSize(pageSize);
        },
      }
    : false;
}
