import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { News } from "hooks";

export type UseCreateNewsResponse = GeneralResponse<number>;

export type UseCreateNewsVariables = Omit<
  News,
  "id" | "isVisible" | "isVisibleMain" | "publishDate" | "readCount"
> &
  Partial<Pick<News, "publishDate">>;

export function useCreateNews(
  props?: MutationParams<UseCreateNewsResponse, unknown, UseCreateNewsVariables>
) {
  return useAPIMutation<UseCreateNewsResponse, unknown, UseCreateNewsVariables>({
    ...props,
    url: "/api/news/create",
    instance: generalRequest,
    method: "POST",
  });
}
