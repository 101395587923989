import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Structure, UseCreateStructureVariables } from "hooks";

export type UseUpdateStructureResponse = GeneralResponse<number>;

export type UseUpdateStructureVariables = Pick<Structure, "id"> & UseCreateStructureVariables;

export function useUpdateStructure(
  props?: MutationParams<UseUpdateStructureResponse, unknown, UseUpdateStructureVariables>
) {
  return useAPIMutation<UseUpdateStructureResponse, unknown, UseUpdateStructureVariables>({
    ...props,
    url: "/api/center-structure/update",
    instance: generalRequest,
    method: "PUT",
  });
}
