import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangeVideoReportVisibleResponse = GeneralResponse<boolean>;

export type UseChangeVideoReportVisibleVariables = ChangeVisibleVariables;

export function useChangeVideoReportVisible(
  props?: MutationParams<
    UseChangeVideoReportVisibleResponse,
    unknown,
    UseChangeVideoReportVisibleVariables
  >
) {
  return useAPIMutation<
    UseChangeVideoReportVisibleResponse,
    unknown,
    UseChangeVideoReportVisibleVariables
  >({
    ...props,
    url: "/api/video/report/change-is-visible",
    instance: generalRequest,
    method: "PUT",
  });
}
