import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { UsefulNullable } from "hooks";

export type UseGetUsefulAllResponse = GeneralResponse<GeneralData<UsefulNullable>>;

export function useGetUsefulAll(props?: QueryParams<UseGetUsefulAllResponse>) {
  return useAPIQuery<UseGetUsefulAllResponse>({
    ...props,
    url: "/api/useful-page/get-all",
    instance: generalRequest,
  });
}
