import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { CorruptionFight } from "hooks";

export type UseCreateCorruptionFightResponse = GeneralResponse<number>;

export type UseCreateCorruptionFightVariables = Omit<
  CorruptionFight,
  "id" | "isVisible" | "publishDate" | "guid" | "url" | "number"
> &
  Partial<Pick<CorruptionFight, "publishDate" | "guid" | "url" | "number">>;

export function useCreateCorruptionFight(
  props?: MutationParams<
    UseCreateCorruptionFightResponse,
    unknown,
    UseCreateCorruptionFightVariables
  >
) {
  return useAPIMutation<
    UseCreateCorruptionFightResponse,
    unknown,
    UseCreateCorruptionFightVariables
  >({
    ...props,
    url: "/api/fighting-corruption/create",
    instance: generalRequest,
    method: "POST",
  });
}
