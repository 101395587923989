import { Suspense, useEffect } from "react";
import { ConfigProvider } from "antd";
import { globalAntdConfig } from "config";
import { CommonInfoProvider } from "context/commonInfo";
import locale from "antd/locale/ru_RU";
import { Layout, PageLoader } from "components/shared";
import { AllRoutes, AuthRoutes } from "Routes";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import useAuthContextValue from "context/auth/useValue";
import "assets/styles/index.scss";
import { generalRequest, generateAxiosInterceptors, my3SoliqRequest } from "services"

function App() {
  const {
    state: { isAuth },
    actions: { setIsAuth },
  } = useAuthContextValue();

  useEffect(() => {
    generateAxiosInterceptors(my3SoliqRequest, () => setIsAuth(false));
    generateAxiosInterceptors(generalRequest, () => setIsAuth(false));
  }, [setIsAuth]);

  const routes = isAuth ? <AllRoutes /> : <AuthRoutes />;

  return (
    <CommonInfoProvider>
      <ConfigProvider theme={globalAntdConfig} locale={locale}>
        <Layout>
          <Suspense fallback={<PageLoader />}>
            {routes}
            <ReactQueryDevtools initialIsOpen={false} />
          </Suspense>
        </Layout>
      </ConfigProvider>
    </CommonInfoProvider>
  );
}

export default App;
