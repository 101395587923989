import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";

const Auth = lazy(() => import("pages/Auth"));

const Dashboard = lazy(() => import("pages/Dashboard"));

const MainBanner = lazy(() => import("pages/Dashboard/modules/Main/Banner"));
const MainMap = lazy(() => import("pages/Dashboard/modules/Main/Map"));
const MainUseful = lazy(() => import("pages/Dashboard/modules/Main/Useful"));

const AboutStructure = lazy(() => import("pages/Dashboard/modules/About/Structure"));
const AboutOfficial = lazy(() => import("pages/Dashboard/modules/About/Official"));
const AboutReglament = lazy(() => import("pages/Dashboard/modules/About/Reglament"));
const AboutDoc = lazy(() => import("pages/Dashboard/modules/About/Doc"));
const AboutVacancy = lazy(() => import("pages/Dashboard/modules/About/Vacancy"));
const AboutTender = lazy(() => import("pages/Dashboard/modules/About/Tender"));

const ActivityPartner = lazy(() => import("pages/Dashboard/modules/Activity/Partner"));
const ActivityCooperation = lazy(() => import("pages/Dashboard/modules/Activity/Cooperation"));

const Project = lazy(() => import("pages/Dashboard/modules/Project"));

const Editor = lazy(() => import("pages/Editor"));
const VacancyBannerEditor = lazy(() => import("pages/Editor/modules/VacancyBannerEditor"));
const StructureEditor = lazy(() => import("pages/Editor/modules/StructureEditor"));
const OfficialEditor = lazy(() => import("pages/Editor/modules/OfficialEditor"));
const ReglamentEditor = lazy(() => import("pages/Editor/modules/ReglamentEditor"));
const NewsEditor = lazy(() => import("pages/Editor/modules/NewsEditor"));
const ProjectEditor = lazy(() => import("pages/Editor/modules/ProjectEditor"));
const SpeechEditor = lazy(() => import("pages/Editor/modules/SpeechEditor"));
const EventEditor = lazy(() => import("pages/Editor/modules/EventEditor"));
const AccreditationEditor = lazy(() => import("pages/Editor/modules/AccreditationEditor"));
const SupportEditor = lazy(() => import("pages/Editor/modules/SupportEditor"));
const CooperationEditor = lazy(() => import("pages/Editor/modules/CooperationEditor"));
const PartnerEditor = lazy(() => import("pages/Editor/modules/PartnerEditor"));
const PageEditor = lazy(() => import("pages/Editor/modules/PageEditor"));

const MediaNews = lazy(() => import("pages/Dashboard/modules/Media/News"));
const MediaPhotoReport = lazy(() => import("pages/Dashboard/modules/Media/PhotoReport"));
const MediaVideoReport = lazy(() => import("pages/Dashboard/modules/Media/VideoReport"));
const MediaSpeech = lazy(() => import("pages/Dashboard/modules/Media/Speech"));
const MediaEvent = lazy(() => import("pages/Dashboard/modules/Media/Event"));

const OpenInfoCorruptionMaterial = lazy(() => import("pages/Dashboard/modules/OpenInfo/CorruptionMaterial"));
const OpenInfoCorruptionFight = lazy(() => import("pages/Dashboard/modules/OpenInfo/CorruptionFight"));

const Appeal = lazy(() => import("pages/Dashboard/modules/Appeal"));
const Contact = lazy(() => import("pages/Dashboard/modules/Contact"));
const General = lazy(() => import("pages/Dashboard/modules/General"));
const Menu = lazy(() => import("pages/Dashboard/modules/Menu"));
const Page = lazy(() => import("pages/Dashboard/modules/Page"));

export function AllRoutes() {
  const routes = useRoutes([
    {
      path: "/",
      children: [
        {
          path: "",
          element: <Navigate to={"dashboard"} replace />,
        },
        {
          path: "dashboard",
          element: <Dashboard />,
          children: [
            {
              path: "",
              element: <Navigate to={"main"} replace />,
            },
            {
              path: "main",
              children: [
                {
                  path: "",
                  element: <Navigate to={"banner"} replace />,
                },
                {
                  path: "banner",
                  element: <MainBanner />,
                },
                {
                  path: "map",
                  element: <MainMap />,
                },
                {
                  path: "useful",
                  element: <MainUseful />,
                },
              ],
            },
            {
              path: "about",
              children: [
                {
                  path: "",
                  element: <Navigate to={"structure"} replace />,
                },
                {
                  path: "structure",
                  element: <AboutStructure />,
                },
                {
                  path: "official",
                  element: <AboutOfficial />,
                },
                {
                  path: "reglament",
                  element: <AboutReglament />,
                },
                {
                  path: "doc",
                  element: <AboutDoc />,
                },
                {
                  path: "vacancy",
                  element: <AboutVacancy />,
                },
                {
                  path: "tender",
                  element: <AboutTender />,
                },
              ],
            },
            {
              path: "activity",
              children: [
                {
                  path: "",
                  element: <Navigate to={"partner"} replace />,
                },
                {
                  path: "partner",
                  element: <ActivityPartner />,
                },
                {
                  path: "cooperation",
                  element: <ActivityCooperation />,
                },
              ]
            },
            {
              path: "project",
              element: <Project />,
            },
            {
              path: "media",
              children: [
                {
                  path: "",
                  element: <Navigate to={"news"} replace />
                },
                {
                  path: "news",
                  element: <MediaNews />
                },
                {
                  path: "photo-report",
                  element: <MediaPhotoReport />
                },
                {
                  path: "video-report",
                  element: <MediaVideoReport />
                },
                {
                  path: "speech",
                  element: <MediaSpeech />
                },
                {
                  path: "event",
                  element: <MediaEvent />
                },
                {
                  path: "accreditation",
                  element: <AccreditationEditor />
                },
              ]
            },
            {
              path: "open-info",
              children: [
                {
                  path: "",
                  element: <Navigate to={"support"} replace />
                },
                {
                  path: "support",
                  element: <SupportEditor />
                },
                {
                  path: "corruption-fight",
                  element: <OpenInfoCorruptionFight />
                },
                {
                  path: "corruption-material",
                  element: <OpenInfoCorruptionMaterial />
                }
              ]
            },
            {
              path: "appeal",
              element: <Appeal />
            },
            {
              path: "contact",
              element: <Contact />
            },
            {
              path: "general",
              element: <General />
            },
            {
              path: "menu",
              element: <Menu />
            },
            {
              path: "Page",
              element: <Page />
            },
            {
              path: "editor",
              element: <Editor />,
              children: [
                {
                  path: "vacancy-banner",
                  element: <VacancyBannerEditor />,
                },
                {
                  path: "structure/:id?",
                  element: <StructureEditor />,
                },
                {
                  path: "official/:id?",
                  element: <OfficialEditor />,
                },
                {
                  path: "reglament/:id?",
                  element: <ReglamentEditor />,
                },
                {
                  path: "news/:id?",
                  element: <NewsEditor />,
                },
                {
                  path: "project/:id?",
                  element: <ProjectEditor />,
                },
                {
                  path: "speech/:id?",
                  element: <SpeechEditor />,
                },
                {
                  path: "event/:id?",
                  element: <EventEditor />,
                },
                {
                  path: "cooperation/:id?",
                  element: <CooperationEditor />,
                },
                {
                  path: "partner/:id?",
                  element: <PartnerEditor />,
                },
                {
                  path: "page/:id?",
                  element: <PageEditor />,
                },
              ],
            },
          ],
        },
      ],
    },
  ]);

  return routes;
}

export function AuthRoutes() {
  return useRoutes([
    {
      path: "*",
      element: <Auth />,
      index: true,
    },
  ]);
}
