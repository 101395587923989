import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeleteCorruptionMaterialResponse = GeneralResponse<boolean>;

export type UseDeleteCorruptionMaterialVariables = DeleteVariables;

export function useDeleteCorruptionMaterial(
  props?: MutationParams<UseDeleteCorruptionMaterialResponse, unknown, UseDeleteCorruptionMaterialVariables>
) {
  return useAPIMutation<UseDeleteCorruptionMaterialResponse, unknown, UseDeleteCorruptionMaterialVariables>({
    ...props,
    url: "/api/corruption-material/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
