import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { ContactNullable } from "hooks";

export type UseGetContactResponse = GeneralResponse<ContactNullable>;

export function useGetContact(props?: QueryParams<UseGetContactResponse>) {
  return useAPIQuery<UseGetContactResponse>({
    ...props,
    url: "/api/contact/get-all",
    instance: generalRequest,
    key: ["get-contact", props?.params]
  });
}
