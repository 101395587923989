import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeleteStructureResponse = GeneralResponse<boolean>;

export type UseDeleteStructureVariables = DeleteVariables;

export function useDeleteStructure(
  props?: MutationParams<UseDeleteStructureResponse, unknown, UseDeleteStructureVariables>
) {
  return useAPIMutation<UseDeleteStructureResponse, unknown, UseDeleteStructureVariables>({
    ...props,
    url: "/api/center-structure/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
