import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { EventNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetEventByIdResponse = GeneralResponse<EventNullable>;

export function useGetEventById(id?: number | string, props?: QueryParams<UseGetEventByIdResponse>) {
  return useAPIQuery<UseGetEventByIdResponse>({
    ...props,
    url: `/api/public-event/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}