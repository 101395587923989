import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { GeneralNullable } from "hooks";

export type UseGetGeneralResponse = GeneralResponse<GeneralNullable>;

export function useGetGeneral(props?: QueryParams<UseGetGeneralResponse>) {
  return useAPIQuery<UseGetGeneralResponse>({
    ...props,
    url: "/api/general-information/get-all",
    instance: generalRequest,
    key: ["get-general", props?.params]
  });
}
