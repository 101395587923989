import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { MenuNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetMenuByIdResponse = GeneralResponse<MenuNullable>;

export function useGetMenuById(id?: number | string, props?: QueryParams<UseGetMenuByIdResponse>) {
  return useAPIQuery<UseGetMenuByIdResponse>({
    ...props,
    url: `/api/menu/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}