import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { VacancyBanner } from "hooks";

export type UseCreateVacancyBannerResponse = GeneralResponse<boolean>;

export type UseCreateVacancyBannerVariables = VacancyBanner;

export function useCreateVacancyBanner(
  props?: MutationParams<UseCreateVacancyBannerResponse, unknown, UseCreateVacancyBannerVariables>
) {
  return useAPIMutation<UseCreateVacancyBannerResponse, unknown, UseCreateVacancyBannerVariables>({
    ...props,
    url: "/api/common-announcement/create",
    instance: generalRequest,
    method: "POST",
  });
}
