import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { EventNullable } from "hooks";

export type UseGetEventAllResponse = GeneralResponse<GeneralData<EventNullable>>;

export function useGetEventAll(props?: QueryParams<UseGetEventAllResponse>) {
  return useAPIQuery<UseGetEventAllResponse>({
    ...props,
    url: "/api/public-event/get-all",
    instance: generalRequest,
    key: ["get-event-all", props?.params]
  });
}
