import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeleteTenderResponse = GeneralResponse<boolean>;

export type UseDeleteTenderVariables = DeleteVariables;

export function useDeleteTender(
  props?: MutationParams<UseDeleteTenderResponse, unknown, UseDeleteTenderVariables>
) {
  return useAPIMutation<UseDeleteTenderResponse, unknown, UseDeleteTenderVariables>({
    ...props,
    url: "/api/tender/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
