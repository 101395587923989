import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeleteVideoReportResponse = GeneralResponse<boolean>;

export type UseDeleteVideoReportVariables = DeleteVariables;

export function useDeleteVideoReport(
  props?: MutationParams<UseDeleteVideoReportResponse, unknown, UseDeleteVideoReportVariables>
) {
  return useAPIMutation<UseDeleteVideoReportResponse, unknown, UseDeleteVideoReportVariables>({
    ...props,
    url: "/api/video/report/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
