import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";

export function useGetFileByGuid(guid?: string, props?: QueryParams<Blob>) {
	return useAPIQuery<Blob>({
		...props,
		url: `/api/files/download/${guid ?? "UNDEFINED"}`,
		instance: generalRequest,
		config: {
			...props?.config,
			responseType: "blob",
		},
	});
}
