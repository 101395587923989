import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Contact } from "hooks";

export type UseCreateContactResponse = GeneralResponse<number>;

export type UseCreateContactVariables = Omit<
  Contact,
  | "id"
  | "createDate"
  | "longitude"
  | "latitude"
  | "hotlinePhone"
  | "devonkhanaPhone"
  | "secretariatPhone"
  | "zipCode"
  | "title"
  | "content"
> &
  Partial<
    Pick<
      Contact,
      | "longitude"
      | "latitude"
      | "hotlinePhone"
      | "devonkhanaPhone"
      | "secretariatPhone"
      | "zipCode"
      | "title"
      | "content"
    >
  >;

export function useCreateContact(
  props?: MutationParams<UseCreateContactResponse, unknown, UseCreateContactVariables>
) {
  return useAPIMutation<UseCreateContactResponse, unknown, UseCreateContactVariables>({
    ...props,
    url: "/api/contact/create",
    instance: generalRequest,
    method: "POST",
  });
}
