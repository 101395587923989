import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { VideoReportNullable } from "hooks";

export type UseGetVideoReportAllResponse = GeneralResponse<GeneralData<VideoReportNullable>>;

export function useGetVideoReportAll(props?: QueryParams<UseGetVideoReportAllResponse>) {
  return useAPIQuery<UseGetVideoReportAllResponse>({
    ...props,
    url: "/api/video/report/get-all",
    instance: generalRequest,
    key: ["get-project-all", props?.params]
  });
}
