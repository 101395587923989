import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { General } from "hooks";

export type UseCreateGeneralResponse = GeneralResponse<number>;

export type UseCreateGeneralVariables = Omit<
  General,
  "id" | "createDate" | "facebook" | "instagram" | "telegram" | "whatsUp" | "weekDay" | "openData"
> &
  Partial<Pick<General, "facebook" | "instagram" | "telegram" | "whatsUp" | "weekDay">>;

export function useCreateGeneral(
  props?: MutationParams<UseCreateGeneralResponse, unknown, UseCreateGeneralVariables>
) {
  return useAPIMutation<UseCreateGeneralResponse, unknown, UseCreateGeneralVariables>({
    ...props,
    url: "/api/general-information/create",
    instance: generalRequest,
    method: "POST",
  });
}
