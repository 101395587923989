import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangeEventVisibleResponse = GeneralResponse<boolean>;

export type UseChangeEventVisibleVariables = ChangeVisibleVariables;

export function useChangeEventVisible(
  props?: MutationParams<
    UseChangeEventVisibleResponse,
    unknown,
    UseChangeEventVisibleVariables
  >
) {
  return useAPIMutation<
    UseChangeEventVisibleResponse,
    unknown,
    UseChangeEventVisibleVariables
  >({
    ...props,
    url: "/api/public-event/change-is-visible",
    instance: generalRequest,
    method: "PUT",
  });
}
