import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangeVacancyVisibleResponse = GeneralResponse<boolean>;

export type UseChangeVacancyVisibleVariables = ChangeVisibleVariables;

export function useChangeVacancyVisible(
  props?: MutationParams<
    UseChangeVacancyVisibleResponse,
    unknown,
    UseChangeVacancyVisibleVariables
  >
) {
  return useAPIMutation<
    UseChangeVacancyVisibleResponse,
    unknown,
    UseChangeVacancyVisibleVariables
  >({
    ...props,
    url: "/api/vacancy/change-is-visible",
    instance: generalRequest,
    method: "PUT",
  });
}
