import { Space } from "antd";
import { File, FileType, FileTypes } from "components/shared/File";

export type FileListProps = {
  files: Array<FileType & { id?: number | string }>;
  handleDelete?: (index: number) => void;
  type: FileTypes;
};

export function FileList({ files, handleDelete, type }: FileListProps) {
  return (
    <Space direction="vertical" size={"middle"}>
      {files.map((file, index) => (
        <File
          file={{
            guid: file.guid,
            name: file.name,
          }}
          handleDelete={handleDelete && (() => handleDelete(index))}
          type={type}
          key={file.id ?? index}
        />
      ))}
    </Space>
  );
}

export default FileList;
