import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeletePartnerResponse = GeneralResponse<boolean>;

export type UseDeletePartnerVariables = DeleteVariables;

export function useDeletePartner(
  props?: MutationParams<UseDeletePartnerResponse, unknown, UseDeletePartnerVariables>
) {
  return useAPIMutation<UseDeletePartnerResponse, unknown, UseDeletePartnerVariables>({
    ...props,
    url: "/api/cooperation-item/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
