import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { PageNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetPageByIdResponse = GeneralResponse<PageNullable>;

export function useGetPageById(id?: number | string, props?: QueryParams<UseGetPageByIdResponse>) {
  return useAPIQuery<UseGetPageByIdResponse>({
    ...props,
    url: `/api/page/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}