import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Contact } from "hooks";

export type UseUpdateContactResponse = GeneralResponse<number>;

export type UseUpdateContactVariables =  Pick<Contact, "id">;

export function useUpdateContact(
  props?: MutationParams<UseUpdateContactResponse, unknown, UseUpdateContactVariables>
) {
  return useAPIMutation<UseUpdateContactResponse, unknown, UseUpdateContactVariables>({
    ...props,
    url: "/api/contact/update",
    instance: generalRequest,
    method: "PUT",
  });
}
