import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeletePositionResponse = GeneralResponse<boolean>;

export type UseDeletePositionVariables = DeleteVariables;

export function useDeletePosition(
  props?: MutationParams<UseDeletePositionResponse, unknown, UseDeletePositionVariables>
) {
  return useAPIMutation<UseDeletePositionResponse, unknown, UseDeletePositionVariables>({
    ...props,
    url: "/api/sp-position/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
