import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import {  GeneralResponse } from "types";
import { Structure } from "hooks";

export type UseCreateStructureResponse = GeneralResponse<number>;

export type UseCreateStructureVariables = Omit<Structure, "id" | "isVisible">;

export function useCreateStructure(
  props?: MutationParams<UseCreateStructureResponse, unknown, UseCreateStructureVariables>
) {
  return useAPIMutation<UseCreateStructureResponse, unknown, UseCreateStructureVariables>({
    ...props,
    url: "/api/center-structure/create",
    instance: generalRequest,
    method: "POST",
  });
}
