import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Tender, UseCreateTenderVariables } from "hooks";

export type UseUpdateTenderResponse = GeneralResponse<number>;

export type UseUpdateTenderVariables = Pick<Tender, "id"> & UseCreateTenderVariables;

export function useUpdateTender(
  props?: MutationParams<UseUpdateTenderResponse, unknown, UseUpdateTenderVariables>
) {
  return useAPIMutation<UseUpdateTenderResponse, unknown, UseUpdateTenderVariables>({
    ...props,
    url: "/api/tender/update",
    instance: generalRequest,
    method: "PUT",
  });
}
