import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Reglament, UseCreateReglamentVariables } from "hooks";

export type UseUpdateReglamentResponse = GeneralResponse<number>;

export type UseUpdateReglamentVariables = Pick<Reglament, "id"> & UseCreateReglamentVariables;

export function useUpdateReglament(
  props?: MutationParams<UseUpdateReglamentResponse, unknown, UseUpdateReglamentVariables>
) {
  return useAPIMutation<UseUpdateReglamentResponse, unknown, UseUpdateReglamentVariables>({
    ...props,
    url: "/api/reglament/update",
    instance: generalRequest,
    method: "PUT",
  });
}
