import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Official } from "hooks";

export type UseCreateOfficialResponse = GeneralResponse<number>;

export type UseCreateOfficialVariables = Omit<
  Official,
  | "id"
  | "isVisible"
  | "whatsAppUrl"
  | "telegramUrl"
  | "instagramUrl"
  | "facebookUrl"
  | "receptionTimes"
  | "email"
  | "ord"
> &
  Partial<
    Pick<
      Official,
      "whatsAppUrl" | "telegramUrl" | "instagramUrl" | "facebookUrl" | "receptionTimes" | "email" | "ord"
    >
  >;

export function useCreateOfficial(
  props?: MutationParams<UseCreateOfficialResponse, unknown, UseCreateOfficialVariables>
) {
  return useAPIMutation<UseCreateOfficialResponse, unknown, UseCreateOfficialVariables>({
    ...props,
    url: "/api/official-person/create",
    instance: generalRequest,
    method: "POST",
  });
}
