import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeleteUsefulResponse = GeneralResponse<boolean>;

export type UseDeleteUsefulVariables = DeleteVariables;

export function useDeleteUseful(
  props?: MutationParams<UseDeleteUsefulResponse, unknown, UseDeleteUsefulVariables>
) {
  return useAPIMutation<UseDeleteUsefulResponse, unknown, UseDeleteUsefulVariables>({
    ...props,
    url: "/api/useful-page/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
