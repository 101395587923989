import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Menu, UseCreateMenuVariables } from "hooks";

export type UseUpdateMenuResponse = GeneralResponse<number>;

export type UseUpdateMenuVariables = Pick<Menu, "id"> & UseCreateMenuVariables;

export function useUpdateMenu(
  props?: MutationParams<UseUpdateMenuResponse, unknown, UseUpdateMenuVariables>
) {
  return useAPIMutation<UseUpdateMenuResponse, unknown, UseUpdateMenuVariables>({
    ...props,
    url: "/api/menu/update",
    instance: generalRequest,
    method: "PUT",
  });
}
