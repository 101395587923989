import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { ReglamentNullable } from "hooks";

export type UseGetReglamentAllResponse = GeneralResponse<GeneralData<ReglamentNullable>>;

export function useGetReglamentAll(props?: QueryParams<UseGetReglamentAllResponse>) {
  return useAPIQuery<UseGetReglamentAllResponse>({
    ...props,
    url: "/api/reglament/get-all",
    instance: generalRequest,
    key: ["get-reglament-all", props?.params]
  });
}
