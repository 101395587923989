import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangeNewsVisibleResponse = GeneralResponse<boolean>;

export type UseChangeNewsVisibleVariables = ChangeVisibleVariables;

export function useChangeNewsVisible(
  props?: MutationParams<
    UseChangeNewsVisibleResponse,
    unknown,
    UseChangeNewsVisibleVariables
  >
) {
  return useAPIMutation<
    UseChangeNewsVisibleResponse,
    unknown,
    UseChangeNewsVisibleVariables
  >({
    ...props,
    url: "/api/news/change-is-visible",
    instance: generalRequest,
    method: "PUT",
  });
}
