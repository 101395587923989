import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { WeekDay } from "hooks";

export type UseGetWeekDayResponse = GeneralResponse<Array<WeekDay>>;

export function useGetWeekDay(props?: QueryParams<UseGetWeekDayResponse>) {
  return useAPIQuery<UseGetWeekDayResponse>({
    ...props,
    url: "/api/sp/get-week-day",
    instance: generalRequest,
  });
}
