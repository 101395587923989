import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeleteMenuResponse = GeneralResponse<boolean>;

export type UseDeleteMenuVariables = DeleteVariables;

export function useDeleteMenu(
  props?: MutationParams<UseDeleteMenuResponse, unknown, UseDeleteMenuVariables>
) {
  return useAPIMutation<UseDeleteMenuResponse, unknown, UseDeleteMenuVariables>({
    ...props,
    url: "/api/menu/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
