import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import {  GeneralResponse } from "types";
import { Position } from "hooks";

export type UseCreatePositionResponse = GeneralResponse<number>;

export type UseCreatePositionVariables = Omit<Position, "id">;

export function useCreatePosition(
  props?: MutationParams<UseCreatePositionResponse, unknown, UseCreatePositionVariables>
) {
  return useAPIMutation<UseCreatePositionResponse, unknown, UseCreatePositionVariables>({
    ...props,
    url: "/api/sp-position/create",
    instance: generalRequest,
    method: "POST",
  });
}
