import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import {  GeneralResponse } from "types";
import { Doc } from "hooks";

export type UseCreateDocResponse = GeneralResponse<number>;

export type UseCreateDocVariables = Omit<Doc, "id" | "isVisible" | "guid" | "url" | "openType"> & Partial<Pick<Doc, "guid" | "url" | "openType">>;

export function useCreateDoc(
  props?: MutationParams<UseCreateDocResponse, unknown, UseCreateDocVariables>
) {
  return useAPIMutation<UseCreateDocResponse, unknown, UseCreateDocVariables>({
    ...props,
    url: "/api/official-document/create",
    instance: generalRequest,
    method: "POST",
  });
}
