import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { CooperationNullable } from "hooks";

export type UseGetCooperationAllResponse = GeneralResponse<GeneralData<CooperationNullable>>;

export function useGetCooperationAll(props?: QueryParams<UseGetCooperationAllResponse>) {
  return useAPIQuery<UseGetCooperationAllResponse>({
    ...props,
    url: "/api/cooperation/get-all",
    instance: generalRequest,
  });
}
