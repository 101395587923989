import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Support } from "hooks";

export type UseCreateSupportResponse = GeneralResponse<number>;

export type UseCreateSupportVariables = Omit<Support, "id" | "isVisible">;

export function useCreateSupport(
  props?: MutationParams<
    UseCreateSupportResponse,
    unknown,
    UseCreateSupportVariables
  >
) {
  return useAPIMutation<
    UseCreateSupportResponse,
    unknown,
    UseCreateSupportVariables
  >({
    ...props,
    url: "/api/support-entrepreneur/create",
    instance: generalRequest,
    method: "POST",
  });
}
