import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Doc, UseCreateDocVariables } from "hooks";

export type UseUpdateDocResponse = GeneralResponse<number>;

export type UseUpdateDocVariables = Pick<Doc, "id"> & UseCreateDocVariables;

export function useUpdateDoc(
  props?: MutationParams<UseUpdateDocResponse, unknown, UseUpdateDocVariables>
) {
  return useAPIMutation<UseUpdateDocResponse, unknown, UseUpdateDocVariables>({
    ...props,
    url: "/api/official-document/update",
    instance: generalRequest,
    method: "PUT",
  });
}
