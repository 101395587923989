import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangePhotoReportVisibleResponse = GeneralResponse<boolean>;

export type UseChangePhotoReportVisibleVariables = ChangeVisibleVariables;

export function useChangePhotoReportVisible(
  props?: MutationParams<
    UseChangePhotoReportVisibleResponse,
    unknown,
    UseChangePhotoReportVisibleVariables
  >
) {
  return useAPIMutation<
    UseChangePhotoReportVisibleResponse,
    unknown,
    UseChangePhotoReportVisibleVariables
  >({
    ...props,
    url: "/api/photo/report/change-is-visible",
    instance: generalRequest,
    method: "PUT",
  });
}
