import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { ReglamentNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetReglamentByIdResponse = GeneralResponse<ReglamentNullable>;

export function useGetReglamentById(id?: number | string, props?: QueryParams<UseGetReglamentByIdResponse>) {
  return useAPIQuery<UseGetReglamentByIdResponse>({
    ...props,
    url: `/api/reglament/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}