import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { PageNullable } from "hooks";

export type UseGetPageAllResponse = GeneralResponse<GeneralData<PageNullable>>;

export function useGetPageAll(props?: QueryParams<UseGetPageAllResponse>) {
  return useAPIQuery<UseGetPageAllResponse>({
    ...props,
    url: "/api/page/get-all",
    instance: generalRequest,
    key: ["get-page-all", props?.params],
  });
}
