import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { CorruptionMaterialNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetCorruptionMaterialByIdResponse = GeneralResponse<CorruptionMaterialNullable>;

export function useGetCorruptionMaterialById(id?: number | string, props?: QueryParams<UseGetCorruptionMaterialByIdResponse>) {
  return useAPIQuery<UseGetCorruptionMaterialByIdResponse>({
    ...props,
    url: `/api/corruption-material/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}