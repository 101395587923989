import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeleteNewsResponse = GeneralResponse<boolean>;

export type UseDeleteNewsVariables = DeleteVariables;

export function useDeleteNews(
  props?: MutationParams<UseDeleteNewsResponse, unknown, UseDeleteNewsVariables>
) {
  return useAPIMutation<UseDeleteNewsResponse, unknown, UseDeleteNewsVariables>({
    ...props,
    url: "/api/news/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
