import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Position } from "hooks";

export type UseUpdatePositionResponse = GeneralResponse<number>;

export type UseUpdatePositionVariables = Position;

export function useUpdatePosition(
  props?: MutationParams<UseUpdatePositionResponse, unknown, UseUpdatePositionVariables>
) {
  return useAPIMutation<UseUpdatePositionResponse, unknown, UseUpdatePositionVariables>({
    ...props,
    url: "/api/sp-position/update",
    instance: generalRequest,
    method: "PUT",
  });
}
