import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Menu } from "hooks";

export type UseCreateMenuResponse = GeneralResponse<number>;

export type UseCreateMenuVariables = Omit<
  Menu,
  "id" | "isVisible" | "isStatic" | "parentName" | "parentId" | "pageId" | "list" | "ord" | "degree" | "link"
> &
  Partial<Pick<Menu, "parentId" | "pageId" | "ord" | "degree" | "link">>;

export function useCreateMenu(
  props?: MutationParams<UseCreateMenuResponse, unknown, UseCreateMenuVariables>
) {
  return useAPIMutation<UseCreateMenuResponse, unknown, UseCreateMenuVariables>({
    ...props,
    url: "/api/menu/create",
    instance: generalRequest,
    method: "POST",
  });
}
