import { NavigateOptions, useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import { Languages } from "types";

export type UseQueryParamsType = Partial<{
  lang: Omit<string, Languages> | Languages;
}>;

export const useQueryParams = <T extends UseQueryParamsType>() => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  const queryParams = query as T;

  const setQueryParams = (params: T) => {
    navigate(`?${qs.stringify({ ...params })}`);
  };

  const addQueryParams = (params: T, options?: NavigateOptions) => {
    navigate(`?${qs.stringify({ ...query, ...params })}`, options);
  };

  return {
    queryParams,
    setQueryParams,
    addQueryParams,
  };
};
