import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Speech, UseCreateSpeechVariables } from "hooks";

export type UseUpdateSpeechResponse = GeneralResponse<number>;

export type UseUpdateSpeechVariables = Pick<Speech, "id"> & UseCreateSpeechVariables;

export function useUpdateSpeech(
  props?: MutationParams<UseUpdateSpeechResponse, unknown, UseUpdateSpeechVariables>
) {
  return useAPIMutation<UseUpdateSpeechResponse, unknown, UseUpdateSpeechVariables>({
    ...props,
    url: "/api/leaders-speech/update",
    instance: generalRequest,
    method: "PUT",
  });
}
