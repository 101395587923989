import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Page } from "hooks";

export type UseCreatePageResponse = GeneralResponse<number>;

export type UseCreatePageVariables = Omit<
  Page,
  "id" | "isVisible" | "menuName" | "menuId" | "appUrl"
> &
  Partial<Pick<Page, "menuId">>;

export function useCreatePage(
  props?: MutationParams<UseCreatePageResponse, unknown, UseCreatePageVariables>
) {
  return useAPIMutation<UseCreatePageResponse, unknown, UseCreatePageVariables>({
    ...props,
    url: "/api/page/create",
    instance: generalRequest,
    method: "POST",
  });
}
