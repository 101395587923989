import dayjs from "dayjs";

export enum CurrDate {
  MONTH = dayjs().month() + 1,
  YEAR = dayjs().year(),
  DAY = dayjs().day(),
}

export const PI = 3.14159265359 as const;

export const DATE_FORMAT = "DD.MM.YYYY";
export const DATE_FORMAT_DASHED = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm:ss";
export const TIME_FORMAT = "HH:mm:ss";
export const SERVER_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ";

export const TIN_LENGTH = 9,
  PINFL_LENGTH = 14;

export const MILLIARD = 1e9,
  MILLION = 1e6,
  MILLE = 1e3;

export const MESSAGE_ERROR = "Xatolik yuz berdi";
export const MESSAGE_SUCCESS = "So'rov muvaffaqiyatli yakunlandi";
export const MESSAGE_REQUIRED = "To'ldiring";
export const MESSAGE_SELECT = "Tanlang";
export const MESSAGE_UPLOAD = "Fayl yuklang";
export const MESSAGE_UPLOAD_SINGLE = "1ta fayl yuklang";
export const MESSAGE_UPLOAD_MIN_1 = "Kamida 1ta fayl yuklang";
export const MESSAGE_URL_MIN_1 = "Kamida 1ta URL kiriting";
export const MESSAGE_FORMAT = "Format noto'g'ri";

export const FORMAT_IMAGE = ".jpg,.jpeg,.png,.gif,.svg,.webp";
export const FORMAT_PHONE = "+998 (##) ###-##-##";

export const PhoneNumberRegExp = /^\+998 \(\d{2}\) \d{3}-\d{2}-\d{2}$/;
export const EmailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const FILE_REPOSITORY_URL = "https://iscad.uz";
export const FILL_AT_LEAST_ONE_LANGUAGE = "Kamida bitta tilda maydonni to'ldiring";
