import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { AccreditationNullable } from "hooks";

export type UseGetAccreditationAllResponse = GeneralResponse<GeneralData<AccreditationNullable>>;

export function useGetAccreditationAll(props?: QueryParams<UseGetAccreditationAllResponse>) {
  return useAPIQuery<UseGetAccreditationAllResponse>({
    ...props,
    url: "/api/accreditation/get-all",
    instance: generalRequest,
    key: ["get-accreditation-all", props?.params]
  });
}
