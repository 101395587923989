import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { VideoReportNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetVideoReportByIdResponse = GeneralResponse<VideoReportNullable>;

export function useGetVideoReportById(id?: number | string, props?: QueryParams<UseGetVideoReportByIdResponse>) {
  return useAPIQuery<UseGetVideoReportByIdResponse>({
    ...props,
    url: `/api/video/report/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}