import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { VacancyNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetVacancyByIdResponse = GeneralResponse<VacancyNullable>;

export function useGetVacancyById(id?: number | string, props?: QueryParams<UseGetVacancyByIdResponse>) {
  return useAPIQuery<UseGetVacancyByIdResponse>({
    ...props,
    url: `/api/vacancy/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}