import { useQuery, QueryKey, UseQueryOptions } from "@tanstack/react-query";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { arrangeURL } from "utils";

export type QueryOptions<
  TData = unknown,
  TError = unknown,
  TKey extends QueryKey = QueryKey
> = Omit<
  UseQueryOptions<AxiosResponse<TData>, AxiosError<TError>, AxiosResponse<TData>, TKey>,
  "queryKey" | "queryFn"
>;

export type QueryResult<
  TData = unknown,
  TError = unknown,
  TKey extends QueryKey = QueryKey
> = ReturnType<typeof useAPIQuery<TData, TError, TKey>>;

export interface QueryParams<
  TData = unknown,
  TError = unknown,
  TKey extends QueryKey = QueryKey
> {
  readonly url?: string;
  readonly params?: unknown;
  readonly options?: QueryOptions<TData, TError, TKey>;
  readonly config?: AxiosRequestConfig<TData>;
  readonly key?: TKey;
  readonly instance?: AxiosInstance;
}

const useAPIQuery = <TData = unknown, TError = unknown, TKey extends QueryKey = QueryKey>({
  url,
  options = {},
  params = {},
  config = {},
  instance,
  key,
}: QueryParams<TData, TError, TKey>) => {
  const request = instance ?? axios;

  return useQuery<AxiosResponse<TData>, AxiosError<TError>, AxiosResponse<TData>, TKey>({
    ...options,
    queryKey: (key ?? [url, params]) as TKey,
    async queryFn() {
      return await request.get(arrangeURL(url), {
        ...config,
        params,
      });
    },
  });
};

export default useAPIQuery;
