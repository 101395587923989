import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangeProjectVisibleResponse = GeneralResponse<boolean>;

export type UseChangeProjectVisibleVariables = ChangeVisibleVariables;

export function useChangeProjectVisible(
  props?: MutationParams<
    UseChangeProjectVisibleResponse,
    unknown,
    UseChangeProjectVisibleVariables
  >
) {
  return useAPIMutation<
    UseChangeProjectVisibleResponse,
    unknown,
    UseChangeProjectVisibleVariables
  >({
    ...props,
    url: "/api/projects/change-is-visible",
    instance: generalRequest,
    method: "PUT",
  });
}
