import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { OfficialNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetOfficialByIdResponse = GeneralResponse<OfficialNullable>;

export function useGetOfficialById(id?: number | string, props?: QueryParams<UseGetOfficialByIdResponse>) {
  return useAPIQuery<UseGetOfficialByIdResponse>({
    ...props,
    url: `/api/official-person/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}