import { QueryClient } from "@tanstack/react-query";
import axios, { AxiosInstance } from "axios";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const my3SoliqRequest = axios.create({
  baseURL: process.env.REACT_APP_MY3_SOLIQ_API,
});

export const generalRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const generateAxiosInterceptors = (
  axiosInstance: AxiosInstance,
  onUnauthorized?: () => void
) => {
  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        onUnauthorized?.();
      }
      return Promise.reject(error);
    }
  );
};

generateAxiosInterceptors(my3SoliqRequest);
generateAxiosInterceptors(generalRequest);
