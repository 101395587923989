import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { PhotoReportNullable } from "hooks";

export type UseGetPhotoReportAllResponse = GeneralResponse<GeneralData<PhotoReportNullable>>;

export function useGetPhotoReportAll(props?: QueryParams<UseGetPhotoReportAllResponse>) {
  return useAPIQuery<UseGetPhotoReportAllResponse>({
    ...props,
    url: "/api/photo/report/get-all",
    instance: generalRequest,
    key: ["get-project-all", props?.params]
  });
}
