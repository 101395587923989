import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { PhotoReportNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetPhotoReportByIdResponse = GeneralResponse<PhotoReportNullable>;

export function useGetPhotoReportById(id?: number | string, props?: QueryParams<UseGetPhotoReportByIdResponse>) {
  return useAPIQuery<UseGetPhotoReportByIdResponse>({
    ...props,
    url: `/api/photo/report/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}