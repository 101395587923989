import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Map } from "hooks";

export type UseUpdateMapResponse = GeneralResponse<boolean>;

export type UseUpdateMapVariables = Omit<Map, "region">;

export function useUpdateMap(
  props?: MutationParams<UseUpdateMapResponse, unknown, UseUpdateMapVariables>
) {
  return useAPIMutation<UseUpdateMapResponse, unknown, UseUpdateMapVariables>({
    ...props,
    url: "/api/interactive-map/update",
    instance: generalRequest,
    method: "PUT",
  });
}
