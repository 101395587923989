import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { PartnerNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetPartnerByIdResponse = GeneralResponse<PartnerNullable>;

export function useGetPartnerById(id?: number | string, props?: QueryParams<UseGetPartnerByIdResponse>) {
  return useAPIQuery<UseGetPartnerByIdResponse>({
    ...props,
    url: `/api/cooperation-item/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}