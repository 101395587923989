import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { CorruptionFightNullable } from "hooks";
import { GeneralResponse } from "types";

export type UseGetCorruptionFightByIdResponse = GeneralResponse<CorruptionFightNullable>;

export function useGetCorruptionFightById(id?: number | string, props?: QueryParams<UseGetCorruptionFightByIdResponse>) {
  return useAPIQuery<UseGetCorruptionFightByIdResponse>({
    ...props,
    url: `/api/fighting-corruption/get/${id ?? "UNDEFINED"}`,
    instance: generalRequest,
  });
}