import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { CorruptionMaterial, UseCreateCorruptionMaterialVariables } from "hooks";

export type UseUpdateCorruptionMaterialResponse = GeneralResponse<number>;

export type UseUpdateCorruptionMaterialVariables = Pick<CorruptionMaterial, "id"> & UseCreateCorruptionMaterialVariables;

export function useUpdateCorruptionMaterial(
  props?: MutationParams<UseUpdateCorruptionMaterialResponse, unknown, UseUpdateCorruptionMaterialVariables>
) {
  return useAPIMutation<UseUpdateCorruptionMaterialResponse, unknown, UseUpdateCorruptionMaterialVariables>({
    ...props,
    url: "/api/corruption-material/update",
    instance: generalRequest,
    method: "PUT",
  });
}
