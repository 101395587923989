import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { ChangeVisibleVariables, GeneralResponse } from "types";

export type UseChangeBannerVisibleResponse = GeneralResponse<boolean>;

export type UseChangeBannerVisibleVariables = ChangeVisibleVariables;

export function useChangeBannerVisible(
  props?: MutationParams<
    UseChangeBannerVisibleResponse,
    unknown,
    UseChangeBannerVisibleVariables
  >
) {
  return useAPIMutation<
    UseChangeBannerVisibleResponse,
    unknown,
    UseChangeBannerVisibleVariables
  >({
    ...props,
    url: "/api/main-banner/change-is-visible",
    instance: generalRequest,
    method: "PUT",
  });
}
