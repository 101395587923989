import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { DeleteVariables, GeneralResponse } from "types";

export type UseDeleteReglamentResponse = GeneralResponse<boolean>;

export type UseDeleteReglamentVariables = DeleteVariables;

export function useDeleteReglament(
  props?: MutationParams<UseDeleteReglamentResponse, unknown, UseDeleteReglamentVariables>
) {
  return useAPIMutation<UseDeleteReglamentResponse, unknown, UseDeleteReglamentVariables>({
    ...props,
    url: "/api/reglament/delete",
    instance: generalRequest,
    method: "DELETE",
  });
}
