import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { NewsNullable } from "hooks";

export type UseGetNewsAllResponse = GeneralResponse<GeneralData<NewsNullable>>;

export function useGetNewsAll(props?: QueryParams<UseGetNewsAllResponse>) {
  return useAPIQuery<UseGetNewsAllResponse>({
    ...props,
    url: "/api/news/get-all",
    instance: generalRequest,
    key: ["get-news-all", props?.params]
  });
}
