import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { Page, UseCreatePageVariables } from "hooks";

export type UseUpdatePageResponse = GeneralResponse<number>;

export type UseUpdatePageVariables = Pick<Page, "id"> & UseCreatePageVariables;

export function useUpdatePage(
  props?: MutationParams<UseUpdatePageResponse, unknown, UseUpdatePageVariables>
) {
  return useAPIMutation<UseUpdatePageResponse, unknown, UseUpdatePageVariables>({
    ...props,
    url: "/api/page/update",
    instance: generalRequest,
    method: "PUT",
  });
}
