import { QueryParams, useAPIQuery } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralData, GeneralResponse } from "types";
import { MapNullable } from "hooks";

export type UseGetMapAllResponse = GeneralResponse<GeneralData<MapNullable>>;

export function useGetMapAll(props?: QueryParams<UseGetMapAllResponse>) {
  return useAPIQuery<UseGetMapAllResponse>({
    ...props,
    url: "/api/interactive-map/get-all",
    instance: generalRequest,
  });
}
