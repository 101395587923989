import { MutationParams, useAPIMutation } from "hooks/custom";
import { generalRequest } from "services";
import { GeneralResponse } from "types";
import { PhotoReport } from "hooks";

export type UseCreatePhotoReportResponse = GeneralResponse<number>;

export type UseCreatePhotoReportVariables = Omit<
  PhotoReport,
  "id" | "isVisible" | "isVisibleMain" | "readCount" | "publishDate"
> &
  Partial<Pick<PhotoReport, "publishDate">>;

export function useCreatePhotoReport(
  props?: MutationParams<
    UseCreatePhotoReportResponse,
    unknown,
    UseCreatePhotoReportVariables
  >
) {
  return useAPIMutation<
    UseCreatePhotoReportResponse,
    unknown,
    UseCreatePhotoReportVariables
  >({
    ...props,
    url: "/api/photo/report/create",
    instance: generalRequest,
    method: "POST",
  });
}
